import React, { useContext, useEffect, useState } from "react";
import dateFormat from 'dateformat';
import { Container, Row, Table, Form } from "react-bootstrap";
import { navigate } from "gatsby";

import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import 'gatsby-theme-starberry-lomondgroup/src/scss/_sitemap.scss';
import { getFormData } from "gatsby-theme-starberry-lomondgroup/src/components/forms/api/Api"
import PaymentTable from "gatsby-theme-starberry-lomondgroup/src/components/Payment/PaymentTable"

import {PageLinks} from "../common/page-links";
import { shasign_pass } from "../common/constant";


// markup
const PaymentVerify = (props) => {

    const[payment_info, setPaymentInfo] = useState("");

    var sha256 = require('js-sha256');

    var payment_id = typeof window !== 'undefined' && (localStorage.getItem("payment_id") ? localStorage.getItem("payment_id") : "");
    // var payment_json = typeof window !== 'undefined' && (localStorage.getItem("payment_info") ? localStorage.getItem("payment_info") : "");

    //console.log("payment_info", payment_json, payment_json && JSON.parse(payment_json))
    //var payment_info = typeof window !== 'undefined' && (payment_json ? JSON.parse(payment_json) : navigate(`/${PageLinks.online_payment}/`));

    useEffect(() => {
        if(payment_id){
            getFormData(payment_id).then(async apiRes => {

                //console.log("apiRes ==>", apiRes);

                if (apiRes?.success === true) {
                    setPaymentInfo(apiRes)
                }
            });
        } else{
            navigate(`/${PageLinks.online_payment}/`)
        }
    }, [payment_id]);

    const goBack = () =>{
        typeof window !== 'undefined' && window.history.go(-1); return false;
    }

    var seo_meta = {title: "Payment Verification", description:"Payment Verification"}

    //console.log("payment_info ==>", payment_info);

    var cur_date = dateFormat(new Date(), "yyyy:mm:dd-hh:MM:ss");
    var order_id = payment_info?.extra?.ORDERID
    //var order_id = `37314`

    var secret_code = shasign_pass;
    var amount = payment_info?.extra?.amount_full+"."+payment_info?.extra?.amount_pence;

    var sharedSecret = process.env.GATSBY_NATEWEST_HASSECRET ? process.env.GATSBY_NATEWEST_HASSECRET : "H%aC@06PEV";
    var hash_algorithm = "HMACSHA256"
    var storename = process.env.GATSBY_NATEWEST_STORENAME ? process.env.GATSBY_NATEWEST_STORENAME : "Julian Wadden & Co Limited"
    var timezone = "Europe/London"
    var txntype = "sale"
    var currency = "826"   // 978,826
    var checkoutoption = "combinedpage"
    var bname = `${payment_info?.extra?.billing_first_name} ${payment_info?.extra?.billing_last_name}`
    //var chargetotal = payment_info?.extra?.amount_full+"."+payment_info?.extra?.amount_pence;
    var chargetotal = amount;
    var transTime = cur_date

    var shasign_str = `${bname}|${chargetotal}|${checkoutoption}|${currency}|${hash_algorithm}|${storename}|${timezone}|${transTime}|${txntype}`

    // const hash = crypto.createHash('sha1').update(shasign_generate).digest('hex')

    var hashHMACSHA256 = hmacSHA256(shasign_str, sharedSecret);
    var extendedhash = Base64.stringify(hashHMACSHA256);

    //var hash_sign = "CL9e2I+xkj2TW27IB+aZl3cBif27C8tsoefQuzxzPoY=";
    var hash_sign = extendedhash;

    console.log("shasign_generate", extendedhash, sharedSecret)
    //var shasign_generate = sha256(shasign_str)
    //var shasign_generate = ""

    return (
        <>
        <div className={`search-header property-header`}>
            <Layout seo_meta={seo_meta}>
            <div className="site-map payment_verify">
                <Container>
                    <Row>
                        <h1>Payment Verification</h1>

                        <Form action="https://www.ipg-online.com/connect/gateway/processing" method="post">
                        {/* <Form action="https://mdepayments.epdq.co.uk/ncol/test/orderstandard.asp" method="post"> */}

                            {/* ============== Payment Fields ============== */}
                            <input type="hidden" name="bname" value={bname} readonly="readonly" />
                            <input type="hidden" name="storename" value={storename} readonly="readonly" />
                            <input type="hidden" name="timezone" value={timezone} readonly="readonly"/>
                            <input type="hidden" name="txntype" value={txntype} readonly="readonly" />
                            <input type="hidden" name="chargetotal" value={chargetotal} readonly="readonly" />
                            <input type="hidden" name="currency" value={currency} readonly="readonly" />
                            <input type="hidden" name="txndatetime" value={transTime} />
                            <input type="hidden" name="hashExtended" value={hash_sign} readonly="readonly" />
                            <input type="hidden" name="hash_algorithm" value={hash_algorithm} readonly="readonly" />
                            <input type="hidden" name="checkoutoption" value={checkoutoption} readonly="readonly" />
                            {/* <input type="hidden" name="responseFailURL" value={`${process.env.GATSBY_SITE_URL}/${PageLinks.payment_failed}`}/>
                            <input type="hidden" name="responseSuccessURL" value={`${process.env.GATSBY_SITE_URL}/${PageLinks.payment_success}`} /> */}
                            {/* ============== Payment Fields ============== */}
                            
                            {payment_info &&
                                <PaymentTable payment_info={payment_info} />
                            }

                            <div className="btn_wrapper">
                                <a className="btn btn-outline" onClick={goBack}>Go Back</a>
                                <input className="btn btn-primary" type={"submit"} value={"Proceed to Secure Payment"}/>
                            </div>

                        </Form>

                    </Row>
                </Container>
                </div>
            </Layout>
        </div>
        </>
    )
}

export default PaymentVerify